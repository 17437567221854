import React from 'react';
import { Button, Typography } from '@heycater/design-system';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import WeareheycaterImg from '@images/homepage/weareheycater.jpeg';

export default function WeAreHeycater() {
  const { t } = useTranslation('homepage');

  return (
    <Wrapper>
      <WeAreHeycaterLeftBox>
        <Title>{t('weAreHeycater.title')}</Title>
        <Subttitle>{t('weAreHeycater.subtitle')}</Subttitle>
        <Paragraph>{t('weAreHeycater.body')}</Paragraph>
        <a
          href="https://www.heycater.com/de/about-us"
          target="_blank"
          rel="noreferrer"
        >
          <StyledButton variant="tertiary">
            {t('weAreHeycater.cta')}
          </StyledButton>
        </a>
      </WeAreHeycaterLeftBox>
      <WeAreHeycaterRightBox>
        <Image src={WeareheycaterImg} height={374} width={460} quality={100} />
      </WeAreHeycaterRightBox>
    </Wrapper>
  );
}

const Title = styled(Typography)`
  font-weight: 700;
  font-size: 20px;
  line-height: 34.5px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 36px;
    line-height: 62.1px;
  }
`;

const Subttitle = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  line-height: 27.6px;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 24px;
    line-height: 41.4px;
  }
`;
const Paragraph = styled(Typography)`
  font-size: 14px;
  line-height: 23.52px;
  margin: ${({ theme }) => theme.spacing(2, 0)};
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 16px;
    line-height: 26.88px;
  }
`;

const StyledButton = styled(Button)`
  border-radius: 47px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin: auto;
  padding: ${({ theme }) => theme.spacing(0, 2)};
  gap: 40px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    width: fill-available !important;
    max-width: 1118px !important;
    padding: 0;
    gap: auto;
  }
`;

const WeAreHeycaterLeftBox = styled.div`
  max-width: 578px;
`;

const WeAreHeycaterRightBox = styled.div`
  img {
    border-radius: 20px;
  }
`;
